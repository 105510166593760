"use client";

import React, { useEffect } from "react";
import GradientText from "../GradientText";
import Image from "next/image";
import Link from "next/link";
import Aos from "aos";
import "aos/dist/aos.css";

export default function HomeSpinOff({ spinOffContent, verticaisContent }) {
  useEffect(() => {
    Aos.init();
  }, []);

  const data = spinOffContent;

  const kretosImage = verticaisContent.files[1];
  const mercadoImage = verticaisContent.files[2];

  return (
    <div className="w-full h-full bg-customColorBlack px-4 2xl:px-24 fhd:px-52 py-10 lg:py-20 fhd:py-28 flex flex-col gap-10 fhd:gap-20">
      <div
        className="h-full pb-10"
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="800"
      >
        <GradientText text={data.textarea} />
      </div>
      <div
        className="w-full h-full flex flex-col lg:flex-row items-center"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="800"
      >
        <div className="w-full lg:w-1/2 h-[280px] lg:h-[380px] 2xl:h-[400px] relative -translate-y-[115px] hover:scale-[1.05] transition duration-700">
          <Link
            href={kretosImage.fileDescription}
            target="_blank"
            aria-label={`Link para a página ${kretosImage.fileName}`}
          >
            <Image
              src={kretosImage.fileUrl}
              alt={kretosImage.fileName}
              fill
              quality={100}
              className="object-contain"
            />
          </Link>
        </div>
        {mercadoImage && (
          <div className="w-full lg:w-1/2 h-[280px] lg:h-[380px] 2xl:h-[400px] relative hover:scale-[1.05] transition duration-700">
            <Link
              href={mercadoImage?.fileDescription}
              target="_blank"
              aria-label={`Link para a página ${mercadoImage?.fileName}`}
            >
              <Image
                src={mercadoImage.fileUrl}
                alt={mercadoImage.fileName}
                fill
                quality={100}
                className="object-contain"
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
